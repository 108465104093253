td.MuiTableCell-root, th.MuiTableCell-root{
    padding: 6px 24px 6px 16px;
    font-size: 12px;
}

th.MuiTableCell-root input {
    font-size: 12px;
}


